
import Navigator from './layout/Navigator';
function App() {

  return (
    <div className="App">
      <Navigator/>
    </div>
  );
}
export default App;
